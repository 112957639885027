import React from "react"

import { PROXY_URL } from "constants/config"
import { LOGOUT } from "constants/routes"
import { getSpaceId, panoDesignerLogout, panoStreamsLogout } from "utils/auth"
import { routes } from "utils/routes"
import { clearSpace } from "utils/storage"


class LogOut extends React.Component {

  async componentDidMount() {
    // Pano logout
    try {
      // Attempt both logouts, continue even if one fails
      await Promise.allSettled([panoDesignerLogout(), panoStreamsLogout()])
    }
    catch (error) {
      console.error(error)
    }

    const space = getSpaceId()
    
    if (space) {
      clearSpace()
    }

    // IDP logout
    window.location.replace(PROXY_URL + routes[LOGOUT])
  }

  render() {
    return null
  }
}

export default LogOut
