import { UnityButton} from "@bit/smartworks.unity-react.unity-core-react"
import React from "react"
import {connect} from "react-redux"

import { navigate } from "actions/navigate"
import LogoIcon from "assets/icons/favicon.ico"
import { colors } from "assets/stylesheets/common"
import { openNewTab } from "components/common/NewTabLink"
import UserPopover from "components/Layout/UserPopover"
import {DOCS, DOCS_ROOT, icons, labels, SPACE_PICKER, SUPPORT, SUPPORT_URL } from "constants/routes"
import { endpoints } from "utils/routes"


const stateToProps = (state) => ({
  location: state.router.location
})

function LayoutHeader(props) {
  const {navigate, location} = props
  
  const navigateWithoutRender = (destiny) => {
    switch (destiny) {
    case DOCS:
      openNewTab(`${DOCS_ROOT}/index.htm`)
      break
    case SUPPORT:
      openNewTab(SUPPORT_URL)
      break
    case SPACE_PICKER:
      navigate({ to: SPACE_PICKER })
    }
  }

  const getHeaderButtons= () => {
    let items = [SUPPORT, DOCS]
    if (location.pathname.substring(1) !== endpoints[SPACE_PICKER]) {
      items.unshift(SPACE_PICKER)
    }
    let headerButtons = []
    items.forEach(item => {
      headerButtons.push(
        <UnityButton
          id={`header-button-${item.toLowerCase()}`}
          key={`key-header-btn-${item.toLowerCase()}`}
          label={labels[item]}
          leftIcon={icons[item]}
          type="borderless"
          onClick={() => navigateWithoutRender(item)}
          style={styles.headerButton}
        />
      )
    })
    return(headerButtons)
  }

  return (
    <>
      <div className="navheader" style={styles.headerContainer}>
        <div style={styles.logoContainer}>
          <img src={LogoIcon} alt="" style={styles.logoIcon}/>
        </div>
        <div style={styles.title}>
          Altair® IoT Studio™
        </div>
        <div style={styles.headerOptions}>
          {getHeaderButtons()}
          <UserPopover />
        </div>
      </div>
    </>
  )
}

export default connect(stateToProps,{navigate})(LayoutHeader)

const styles = {
  headerContainer: {
    flex: "auto",
    display: "flex",
    flexDirection: "row",
  },
  headerIcon: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  headerButton: {
    justifyItems: "center",
    display: "inline-grid",
    // paddingTop: "2px"
  },
  headerOptions: {
    marginLeft: "auto",
    marginTop: "-1px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    justifyItems: "center",
    alignItems: "center",

  },
  logoIcon: {
    height: "18px",
    display: "inline-block",
    verticalAlign: "middle"
  },
  logoContainer: {
    paddingRight: "9px",
    borderRight: `1px solid ${colors.gray}`
  },
  title: {
    paddingInline: "10px",
    fontFamily: "Noto",
    fontSize: "14px",
    fontWeight: "bold"
  },
  username: {
    fontWeight: 300,
  },
  email: {
    fontWeight: 300
  },
  linksText: {
    font: `${colors.charcoal}`
  },
  contentPopover: {
    padding: 0,
    margin: 0,
    lineHeight: "27px",
  },
  contentItem: {
    margin: "5px",
  }
}
