import React, { Component } from "react"

import "components/common/common.css"
import UnityDropdown from "components/unity/UnityDropdown"

import { dropdownInputStyle, errorLabelStyles } from "./InputStyles"


class DropdownInputNew extends Component {
  render() {
    const {
      input,
      input: {
        value: inputValue,
        onChange=()=>{}
      }={},
      meta: {
        error,
        pristine,
        touched
      }={},
      inputType="single-select",
      value: passedValue,
      onValueChange: customOnValueChange,
      customStyle={},
      customErrorLabelStyles={},
      containerStyle={},
      trackDirty,
      hideError=false,
      hideErrorUntilTouched,
      ...rest
    } = this.props

    const dirtyInputClassName = rest.label ? "dirty-input" : "dirty-input dirty-input-no-label"
    const className = trackDirty? `${!pristine? dirtyInputClassName : ""}${error? " invalid" : ""}` : ""
    const value = passedValue ?? inputValue

    return (
      <div style={containerStyle}>
        <div style={{ position: "relative" }} className={className}>
          <UnityDropdown
            {...input}
            onValueChange={(selectedValue, selected) => {
              if (inputType === "multi-select") {
                onChange(selected, value)
                if (customOnValueChange instanceof Function) {
                  customOnValueChange(selected || "", value)
                }
              } else {
                onChange(selectedValue, value)
                if (customOnValueChange instanceof Function) {
                  customOnValueChange(selectedValue || "", value)
                }
              }
            }}
            style={{ ...dropdownInputStyle, ...customStyle }}
            selected={value? (Array.isArray(value) ? value : [value]) : undefined}
            inputType={inputType}
            {...rest}
          />
        </div>
        {!hideError && (
          <p
            id={`error-text${rest.id ? `-${rest.id}` : ""}`}
            style={{display: "flex", height: 20, fontSize: 10, fontWeight: 400, margin: 0}}
          >
            {!!error && (!hideErrorUntilTouched || touched) && (
              <span style={{...errorLabelStyles, ...customErrorLabelStyles}}>
                {error}
              </span>
            )}
          </p>
        )}
      </div>
    )
  }
}

export default DropdownInputNew
