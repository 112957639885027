import { parseApiErrorMessage, post } from "utils/api"

import { addMessage, GLOBAL_NOTIFICATIONS, MESSAGE_TYPE_ERROR } from "./notifications"


// REQUESTS

export async function customQueryRequest(body) {
  const endpoint = "/query/cursor"
  try {
    const response = await post(endpoint, body, {beta: true})
    const { data } = response
    return data
  } catch (error) {
    addMessage({
      target: GLOBAL_NOTIFICATIONS,
      text: "Query could not be processed",
      subtext: parseApiErrorMessage(error),
      type: MESSAGE_TYPE_ERROR
    })
  }
}

// Dropdown options for directions
export const directionOptions = [
  { id: "any", label: "Any" },
  { id: "inbound", label: "Inbound" },
  { id: "outbound", label: "Outbound" }
]

// Background color array for nodes
export const backgroundColors = ["#FA4616", "#005776", "#FFA300", "#2DCCD3", "#642667", "#47A23F", "#A7E6D7", "#FFC600", "#A2AAAD"]
