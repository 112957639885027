/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"
import { uniq } from "lodash"

import { CLEAN_SPACE } from "actions/auth"
import { customQueryRequest } from "utils/links"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
} from "utils/notifications"

import { makeActions } from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setLinksAction",
  "setThingIdAction"
]
const {
  setLinksAction,
  setThingIdAction
} = makeActions("links", actionList)

/* === INITIAL STATE === */
const initialState = {
  links: [],
  thindIdSearched: undefined
}

/* === Reducer === */
export default createReducer(initialState, {

  [setLinksAction]: (state, { payload: { linkNodes }}={}) => ({
    ...state,
    links: linkNodes,
  }),
  [setThingIdAction]: (state, { payload: { thingIdSearched }}={}) => ({
    ...state,
    thingIdSearched: thingIdSearched
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getLinkNodes = (queryArangoDB) => {
  //Empty queryArangoDB object means we want to clear the links
  if (Object.keys(queryArangoDB).length === 0) {
    return async dispatch => {
      dispatch(setLinksAction({linkNodes: {}}))
      return {}
    }
  }

  return async dispatch => {
    try {
      const linkNodes = await customQueryRequest(queryArangoDB)
      dispatch(setLinksAction({linkNodes}))
      return linkNodes
    } catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Links could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setThingIdInput = (thingIdSearched) => {
  return async dispatch => {
    dispatch(setThingIdAction({thingIdSearched}))
  }
}

/* === UTILS === */
const thingAttributes = ["@type", "category", "links.rel", "model.version", "property", "title"]

export const makeThingsFilterOptions = () => thingAttributes.map(o => ( {id: o, label: o}))

export const makeQuery = ({
  thingID, // mandatory parameter
  limit = 10, // default limit if not provided
  depth = 10, // default depth if not provided
  direction = "any", // default direction if not provided
  filters = [] // optional filters for @type, category, links.rel, model.version, property, title
}) => {
  const bindVars = {}
  const validDepth = Math.max(1, depth) // Ensure depth is at least 1
  const validLimit = Math.max(1, limit) // Ensure limit is at least 1
  const directionFilter = direction || "any"
  
  const forStrings = ["WITH things"]
  forStrings.push(`LET mainNode = DOCUMENT('things/${thingID}')`)

  const filterStrings = []

  let typeFilter = {
    arrayFilters: [],
    stringFilters: []
  }

  // Array to store category names for filtering
  const categoryNames = []
  const excludeCategoryNames = []
  let hasCategoryFilter = false
  let hasExcludeCategoryFilter = false
  
  // Apply optional filters based on attributes such as @type, category, links.rel, etc.
  const optionalFilterMappings = {
    "@type": "v.@type",
    "category": "v.categories",
    "links.rel": "e.rel",
    "model.version": "v.model.version",
    "title": "v.title"
  }
  
  filters.forEach(f => {
    const bindVarName = createBindVarName(f, filters) // Utility to generate bind variable names

    if (f.attribute === "property") {
      // For property filters, store both key and value in bindVars
      bindVars[bindVarName] = { key: f.key, value: f.value, type: f.type }
      filterStrings.push(
        `FILTER v.status[@${bindVarName}.key] ${f.operator} @${bindVarName}.value AND v.properties[@${bindVarName}.key].type == @${bindVarName}.type`
      )
    } else if (optionalFilterMappings[f.attribute]) {
      // For other attributes, store just the value in bindVars
      bindVars[bindVarName] = f.value
    
      if (f.attribute === "category") {
        // Handle categories with different operators (== or !=)
        if (f.operator === "==") {
          categoryNames.push(`@${bindVarName}`)
          hasCategoryFilter = true
        } else if (f.operator === "!=") {
          excludeCategoryNames.push(`@${bindVarName}`)
          hasExcludeCategoryFilter = true
        }
      } else if (f.attribute === "@type") {
        const operator = f.operator === "==" ? "IN" : "NOT IN"
        const object = typeFilter
        object.arrayFilters.push(`@${bindVarName} ${operator} v.${escapeSpecialCharacter(f.attribute)}`)
        object.stringFilters.push(`@${bindVarName} ${f.operator} v.${escapeSpecialCharacter(f.attribute)}`)
      } else {
        filterStrings.push(
          `FILTER ${optionalFilterMappings[f.attribute]} ${f.operator} @${bindVarName}`
        )
      }
    }
  })
  
  // If there are category filters, include the category name lookup logic
  if (hasCategoryFilter) {
    const categoryNamesString = categoryNames.join(", ")
        
    forStrings.push(`LET categoryNames = [${categoryNamesString}]
        LET categoriesIn = (
          FOR c IN categories
            FILTER c.name IN categoryNames
            RETURN c._id
        )
        FILTER LENGTH(categoriesIn) == LENGTH(categoryNames)`
    )
    
    // Filter for matching categories between things and the category list
    filterStrings.push("FILTER LENGTH(INTERSECTION(categoriesIn, v.categories)) == LENGTH(categoriesIn)")
  }
  
  // Handle category filters with operator "!="
  if (hasExcludeCategoryFilter) {
    const excludeCategoryNamesString = excludeCategoryNames.join(", ")
          
    forStrings.push(`LET excludeCategoryNames = [${excludeCategoryNamesString}]
        LET excludeCategories = (
          FOR c IN categories
            FILTER c.name IN excludeCategoryNames
            RETURN c._id
        )`)
      
    // Ensure the thing does not have any of the exclude categories
    filterStrings.push("FILTER LENGTH(INTERSECTION(excludeCategories, v.categories)) == 0")
  }
  
  forStrings.push(`FOR v, e, p IN 1..${validDepth} ANY 'things/${thingID}' links`)

  // Determine direction based on edges
  forStrings.push(`LET direction = (p.edges[0]._from == 'things/${thingID}' ? 'outbound' : (p.edges[0]._to == 'things/${thingID}' ? 'inbound' : 'any'))`)
  
  // Apply direction filter
  filterStrings.push(`FILTER '${directionFilter}' == 'any' || (direction == '${directionFilter}')`)
  

  if (typeFilter.arrayFilters.length) filterStrings.push(`FILTER (${typeFilter.arrayFilters.join(" AND ")}) OR (${typeFilter.stringFilters.join(" AND ")})`)

  // Add limit clause
  forStrings.push(`LIMIT ${validLimit}`)
  
  // Construct the final query with depth and returns
  const finalQuery = [
    uniq(forStrings).join("\n"),
    uniq(filterStrings).join("\n"),
    "RETURN { mainNode: mainNode, linkedThing: v, link: e, direction: direction, depth: LENGTH(p.vertices) - 1 }"
  ].filter(Boolean).join("\n")

  return {
    query: finalQuery,
    count: true,
    bindVars
  }
}

// Utility function to generate bind variable names (for optional filters)
function createBindVarName(filter, filters) {
  const index = filters.indexOf(filter)
  return `${filter.attribute.replace(/\W/g, "_")}_${index}`
}

const escapeSpecialCharacter = (attribute) => attribute.startsWith("@") ? "`"+attribute+"`" : attribute

